import Vue from "vue";

import "./styles/quasar.scss";
import "quasar/dist/quasar.ie.polyfills";
import lang from "quasar/lang/pt-br.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/mdi-v5/mdi-v5.css";
//import "@quasar/extras/mdi-v3/mdi-v3.css";
import Quasar from 'quasar/src/vue-plugin.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';;

Vue.use(Quasar, {
  config: {},
  plugins: { Loading, Notify, Dialog, QSpinnerHourglass },
  lang: lang,
  extras: ["roboto-font"]
});
