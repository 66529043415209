export default function stringHour() {
  const dateNow = new Date();

  let hh = twoDigits(dateNow.getHours());
  let mm = twoDigits(dateNow.getMinutes());
  let ss = twoDigits(dateNow.getSeconds());

  return `${hh}:${mm}:${ss}`;
}

function twoDigits(number) {
  let num = String(number).length === 1 ? `0${number}` : number;
  return num;
}
