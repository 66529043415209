import firebaseApp from "@/firebase";
import firebaseAuth from "firebase/app";
import "firebase/auth";

const auth = firebaseApp.auth();
const db = firebaseApp.firestore();

export async function login({ commit }, { email, password }) {
  try {
    commit("shared/setLoadingMessage", "Acessando. Aguarde...", { root: true });
    await auth.setPersistence(firebaseAuth.auth.Auth.Persistence.LOCAL);
    const fbUSer = await auth.signInWithEmailAndPassword(email, password);

    const userId = fbUSer.user.uid;

    const userDoc = await db
      .collection("users")
      .doc(userId)
      .get();

    const user = { ...userDoc.data(), id: userId };

    if (user.isActive) {
      commit("setLoggedInUser", user);
    } else {
      commit("setLoggedInUser", null);
      commit(
        "shared/setNotification",
        { message: "Usuário não ativo", color: "red", position: "top" },
        { root: true }
      );
    }
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error.code);
    commit("setLoggedInUser", null);
    commit("shared/setLoadingMessage", null, { root: true });
    commit(
      "shared/setNotification",
      { message: "Falha na autenticacao", color: "red", position: "top" },
      { root: true }
    );
  }
}

export async function logout({ commit }) {
  try {
    await auth.signOut();
    commit("setLoggedInUser", null);
  } catch (error) {
    console.error(error);
    commit(
      "shared/setNotification",
      { message: "Falha na autenticacao", color: "red", position: "top" },
      { root: true }
    );
  }
}

export async function getUsers({ commit }) {
  try {
    commit("shared/setLoadingMessage", "Atualizando listagem de usuarios", {
      root: true
    });

    const users = [];
    const snapshot = await db.collection("users").get();
    snapshot.forEach(doc => {
      users.push({ ...doc.data(), id: doc.id });
    });
    commit("setUsers", users);
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      { message: "Falha ao buscar os usuarios", color: "red", position: "top" },
      { root: true }
    );
  }
}

export async function manageUser({ dispatch, commit }, payload) {
  let {
    id,
    email,
    isActive,
    isAdministrator,
    name,
    protheusUserCode
  } = payload;
  let messageNotify = "alterado";
  try {
    commit("shared/setLoadingMessage", "Enviando dados. Aguarde...", {
      root: true
    });

    if (!id) {
      messageNotify = "incluído";
      const userAuth = await auth.createUserWithEmailAndPassword(
        payload.email,
        "123456"
      );
      id = userAuth.user.uid;
    }

    await db
      .collection("users")
      .doc(id)
      .set({ email, isActive, isAdministrator, name, protheusUserCode });

    commit("shared/setLoadingMessage", null, {
      root: true
    });

    commit(
      "shared/setNotification",
      {
        message: `Usuário ${messageNotify} com sucesso`,
        color: "green",
        position: "top"
      },
      { root: true }
    );

    await dispatch("getUsers");
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, {
      root: true
    });
    commit(
      "shared/setNotification",
      { message: "Falha ao incluir o usuário", color: "red", position: "top" },
      { root: true }
    );
  }
}

export async function sendRecoverPasswordEmail({ commit }, email) {
  if (!email || email.trim().length <= 0) {
    commit(
      "shared/setNotification",
      {
        message: "Informe o e-mail para recuperar a senha",
        color: "yellow-9",
        position: "top"
      },
      { root: true }
    );
    return;
  }
  try {
    await auth.sendPasswordResetEmail(email);
    commit(
      "shared/setNotification",
      {
        message: `Por favor cheque o email enviado para ${email} e siga a instruções para resetar a senha.`,
        color: "green",
        position: "top"
      },
      { root: true }
    );
  } catch (error) {
    commit(
      "shared/setNotification",
      {
        message: `Houve algum erro no envio do email de recuperaçào de senha.`,
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}
