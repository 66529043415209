<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState("shared", ["loadingMessage", "notification"])
  },
  methods: {
    ...mapMutations("shared", ["setNotification"])
  },
  watch: {
    loadingMessage(newValue) {
      if (newValue) {
        this.$q.loading.show({
          message: newValue
        });
      } else {
        this.$q.loading.hide();
      }
    },
    notification(newValue) {
      if (newValue) {
        this.$q.notify(newValue);
        this.setNotification(null);
      }
    }
  }
};
</script>
