import { routes } from "@/router";

export function appLinks() {
  const temp = [];

  routes.forEach(route => {
    if (route.meta && (route.meta.showDrawer || route.meta.showHome)) {
      temp.push({ ...route.meta, path: route.path, name: route.name });
    }
    if (route.children) {
      route.children.forEach(child => {
        if (child.meta && (child.meta.showDrawer || child.meta.showHome)) {
          temp.push({ ...child.meta, path: child.path, name: child.name });
        }
      });
    }
  });

  return temp;
}
