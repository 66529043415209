export default function stringNow() {
  const dateNow = new Date();
  const dia =
    String(dateNow.getDate()).length === 1
      ? "0" + dateNow.getDate()
      : dateNow.getDate();
  const mes =
    String(dateNow.getMonth() + 1).length === 1
      ? "0" + (dateNow.getMonth() + 1)
      : dateNow.getMonth() + 1;
  const ano = dateNow.getFullYear();

  const dtString = `${ano}${mes}${dia}`;
  return dtString;
}
