import firebaseApp from "../../../firebase";
import stringNow from "../../../helpers/stringNow";

const db = firebaseApp.firestore();

export async function getAgreement({ commit, rootState }) {
  const { protheusUserCode } = rootState.users.loggedInUser;

  try {
    commit("shared/setLoadingMessage", "Atualizando Acordos Comerciais", {
      root: true
    });

    const agreement = [];
    const snapshot = await db
      .collection("tradeAgreement")
      .where("protheusUser", "==", protheusUserCode)
      .where("status", "==", "waiting_analysis")
      .get();
    snapshot.forEach(doc => {
      agreement.push({ ...doc.data(), id: doc.id });
    });

    commit("setAgreements", agreement);
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Acordos Comercias",
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}

export async function getAgreementById({ commit }, agreementId) {
  try {
    commit("shared/setLoadingMessage", "Buscando Acordo Comercial", {
      root: true
    });

    const agreement = await db
      .collection("tradeAgreement")
      .doc(agreementId)
      .get()
      .then(doc => {
        return { ...doc.data(), id: doc.id };
      });
    commit("shared/setLoadingMessage", null, { root: true });
    return agreement;
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar o Acordo Comercial",
        color: "red",
        position: "top"
      },
      { root: true }
    );
    throw new Error(error.message);
  }
}

export async function approvesRejectsTradeAgreement(
  { commit, rootState },
  payload
) {
  const { request, response, reasonReject } = payload;
  const { id } = request;

  commit("shared/setLoadingMessage", "Enviando resposta. Aguarde...", {
    root: true
  });

  try {
    const agreementFb = await db
      .collection("tradeAgreement")
      .doc(id)
      .get()
      .then(doc => doc.data());
    const { approvalType } = agreementFb;
    agreementFb.status = "response_sent";
    agreementFb.response = response;

    let horario = new Date(Date.now());
    let hour =
      String("0").repeat(2 - String(horario.getHours()).length) +
      horario.getHours();
    let minute =
      String("0").repeat(2 - String(horario.getMinutes()).length) +
      horario.getMinutes();
    let second =
      String("0").repeat(2 - String(horario.getSeconds()).length) +
      horario.getSeconds();

    const hora = `${hour}:${minute}:${second}`;

    const { protheusUserCode } = rootState.users.loggedInUser;
    let hierarchy = agreementFb.approvalHierarchy;
    let hierarchyIndex = agreementFb.approvalHierarchy.findIndex(
      idx => idx.protheusUser === protheusUserCode
    );

    hierarchy[hierarchyIndex].approvalDate = stringNow();
    hierarchy[hierarchyIndex].approvalHour = hora;
    hierarchy[hierarchyIndex].status =
      response === "approved" ? "Aprovado" : "Reprovado";

    await db
      .collection("tradeAgreement")
      .doc(id)
      .update({
        approvalHierarchy: [...hierarchy]
      });

    const batch = db.batch();
    const setRef = db.collection("tradeAgreement").doc(id);
    batch.update(setRef, {
      status: "response_sent",
      reasonRejection: reasonReject,
      approvalDate: stringNow(),
      approvalHour: hora,
      response
    });

    if (approvalType === "level") {
      const querySnapshot = await db
        .collection("tradeAgreement")
        .where("branchCode", "==", agreementFb.branchCode)
        .where("agreementNumber", "==", agreementFb.agreementNumber)
        .where("level", "==", agreementFb.level)
        .where("status", "==", "waiting_analysis")
        .get();

      querySnapshot.forEach(doc => {
        if (doc.id !== id) {
          const updateRef = db.collection("tradeAgreement").doc(doc.id);
          batch.update(updateRef, {
            status: "analyzed_by_another_user",
            reasonRejection: reasonReject,
            approvalDate: stringNow(),
            approvalHour: hora,
            response
          });
        }
      });
    }

    await batch.commit();
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });
    throw new Error("Falha no envio da resposta. Tente novamente.");
  }
}
