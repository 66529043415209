import firebaseApp from "@/firebase";
import stringNow from "../../../helpers/stringNow";

const db = firebaseApp.firestore();

export async function getOrders({ commit, rootState }) {
  const { protheusUserCode } = rootState.users.loggedInUser;
  try {
    commit("shared/setLoadingMessage", "Atualizando Pedidos de Compra", {
      root: true
    });

    const orders = [];
    const snapshot = await db
      .collection("purchaseOrders")
      .where("protheusUser", "==", protheusUserCode)
      .where("status", "==", "waiting_analysis")
      .get();
    snapshot.forEach(doc => {
      orders.push({ ...doc.data(), id: doc.id });
    });
    commit("setOrders", orders);
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Pedidos de Compra",
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}

export async function getOrderById({ commit }, orderId) {
  try {
    commit("shared/setLoadingMessage", "Buscando Pedido de Compra", {
      root: true
    });

    const order = await db
      .collection("purchaseOrders")
      .doc(orderId)
      .get()
      .then(doc => {
        return { ...doc.data(), id: doc.id };
      });
    commit("shared/setLoadingMessage", null, { root: true });
    return order;
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Pedido de Compra",
        color: "red",
        position: "top"
      },
      { root: true }
    );
    throw new Error(error.message);
  }
}

export async function approvesRejectsPurchaseOrder(
  { commit, rootState },
  payload
) {
  const { order, response, reasonReject } = payload;
  const { id } = order;

  commit("shared/setLoadingMessage", "Enviando resposta. Aguarde...", {
    root: true
  });
  try {
    const orderFb = await db
      .collection("purchaseOrders")
      .doc(id)
      .get()
      .then(doc => doc.data());
    const { approvalType } = orderFb;
    orderFb.status = "response_sent";
    orderFb.response = response;

    let horario = new Date(Date.now());
    let hour =
      String("0").repeat(2 - String(horario.getHours()).length) +
      horario.getHours();
    let minute =
      String("0").repeat(2 - String(horario.getMinutes()).length) +
      horario.getMinutes();
    let second =
      String("0").repeat(2 - String(horario.getSeconds()).length) +
      horario.getSeconds();

    const hora = `${hour}:${minute}:${second}`;

    const snapshot = await db
      .collection("historyApproval")
      .where("id", "==", id)
      .get();

    snapshot.forEach(async doc => {
      const { protheusUserCode } = rootState.users.loggedInUser;
      const idHistory = doc.id;

      let hierarchy = doc.data().approvalHierarchy;
      let hierarchyIndex = hierarchy.findIndex(
        idx => idx.protheusUser === protheusUserCode
      );

      hierarchy[hierarchyIndex].approvalDate = stringNow();
      hierarchy[hierarchyIndex].approvalHour = hora;
      hierarchy[hierarchyIndex].status =
        response === "approved" ? "Aprovado" : "Reprovado";

      await db
        .collection("historyApproval")
        .doc(idHistory)
        .update({
          approvalHierarchy: [...hierarchy]
        });
    });

    const batch = db.batch();
    const setRef = db.collection("purchaseOrders").doc(id);
    batch.update(setRef, {
      status: "response_sent",
      reasonRejection: reasonReject,
      response
    });

    if (approvalType === "level") {
      const querySnapshot = await db
        .collection("purchaseOrders")
        .where("branchCode", "==", orderFb.branchCode)
        .where("orderNumber", "==", orderFb.orderNumber)
        .where("level", "==", orderFb.level)
        .where("status", "==", "waiting_analysis")
        .get();

      querySnapshot.forEach(doc => {
        if (doc.id !== id) {
          const updateRef = db.collection("purchaseOrders").doc(doc.id);
          batch.update(updateRef, {
            status: "analyzed_by_another_user",
            reasonRejection: reasonReject,
            response
          });
        }
      });
    }

    await batch.commit();
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });
    throw new Error("Falha no envio da resposta. Tente novamente.");
  }
}
