import axios from "axios";

export async function getHistory({ commit, rootState }, orderCode) {
  const { protheusUserCode } = rootState.users.loggedInUser;

  try {
    commit(
      "shared/setLoadingMessage",
      "Atualizando com Histórico Pedidos de Compra",
      {
        root: true
      }
    );

    const history = [];
    const urlAPI = `https://api.grupocicopal.com.br:8204/api/historicoPedido/?CodPedido=${orderCode}&CodUsr=${protheusUserCode}`;
    const response = await axios.get(urlAPI);

    response.data.forEach(doc => {
      history.push({ ...doc, id: doc.cnpj });
    });

    commit("setHistory", history);
    commit("shared/setLoadingMessage", null, { root: true });
    return history;
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Pedido de Compra",
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}
