import firebaseApp from "@/firebase";
// import stringNow from "../../../helpers/stringNow";

const db = firebaseApp.firestore();

export async function getRequests({ commit, rootState }) {
  const { protheusUserCode } = rootState.users.loggedInUser;
  try {
    commit("shared/setLoadingMessage", "Atualizando Solicitações de Compra", {
      root: true
    });

    const requests = [];
    const snapshot = await db
      .collection("purchaseRequests")
      .where("protheusUser", "==", protheusUserCode)
      .where("status", "==", "waiting_analysis")
      .get();
    snapshot.forEach(doc => {
      requests.push({ ...doc.data(), id: doc.id });
    });

    commit("setRequests", requests);
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Solicitações de Compra",
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}

export async function getRequestById({ commit }, requestId) {
  try {
    commit("shared/setLoadingMessage", "Buscando Pedido de Compra", {
      root: true
    });

    const request = await db
      .collection("purchaseRequests")
      .doc(requestId)
      .get()
      .then(doc => {
        return { ...doc.data(), id: doc.id };
      });
    commit("shared/setLoadingMessage", null, { root: true });
    return request;
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Pedido de Compra",
        color: "red",
        position: "top"
      },
      { root: true }
    );
    throw new Error(error.message);
  }
}

export async function approvesRejectsPurchaseRequest(
  // { commit, rootState },
  { commit },
  payload
) {
  const { request, response } = payload;
  const { id } = request;

  commit("shared/setLoadingMessage", "Enviando resposta. Aguarde...", {
    root: true
  });
  try {
    const requestFb = await db
      .collection("purchaseRequests")
      .doc(id)
      .get()
      .then(doc => doc.data());
    const { approvalType } = requestFb;
    requestFb.status = "response_sent";
    requestFb.response = response;

    //MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
    /* //~> PRECISAMOS VALIDAR A ORDEM DE ENVIO DAS SCs
    const snapshot = await db
      .collection("historyApproval")
      .where("id", "==", id)
      .get();

    snapshot.forEach(async doc => {
      const { protheusUserCode } = rootState.users.loggedInUser;
      const idHistory = doc.id;

      let hierarchy = doc.data().approvalHierarchy;
      let hierarchyIndex = hierarchy.findIndex(
        idx => idx.protheusUser === protheusUserCode
      );

      hierarchy[hierarchyIndex].approvalDate = stringNow();
      hierarchy[hierarchyIndex].status =
        response === "approved" ? "Aprovado" : "Reprovado";

      await db
        .collection("historyApproval")
        .doc(idHistory)
        .update({
          approvalHierarchy: [...hierarchy]
        });
    });
    */
    //MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM

    const batch = db.batch();
    const setRef = db.collection("purchaseRequests").doc(id);
    batch.update(setRef, {
      status: "response_sent",
      response
    });

    if (approvalType === "level") {
      const querySnapshot = await db
        .collection("purchaseRequests")
        .where("branchCode", "==", requestFb.branchCode)
        .where("requestNumber", "==", requestFb.requestNumber)
        .where("level", "==", requestFb.level)
        .where("status", "==", "waiting_analysis")
        .get();

      querySnapshot.forEach(doc => {
        if (doc.id !== id) {
          const updateRef = db.collection("purchaseRequests").doc(doc.id);
          batch.update(updateRef, {
            status: "analyzed_by_another_user",
            response
          });
        }
      });
    }

    await batch.commit();
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });
    throw new Error("Falha no envio da repsosta. Tente novamente.");
  }
}
