import firebaseApp from "../../../firebase";
import stringHour from "../../../helpers/stringHour";
import stringNow from "../../../helpers/stringNow";

const db = firebaseApp.firestore();

export async function getFinancialTitle({ commit, rootState }) {
  const { protheusUserCode } = rootState.users.loggedInUser;

  try {
    commit("shared/setLoadingMessage", "Atualizando os Títulos Financeiros", {
      root: true
    });

    const financialtitle = [];
    const snapshot = await db
      .collection("financialTitleApproval")
      .where("protheusUser", "==", protheusUserCode)
      .where("status", "==", "waiting_analysis")
      .get();

    snapshot.forEach(doc => {
      financialtitle.push({ ...doc.data(), id: doc.id });
    });

    commit("setfinancialtitle", financialtitle);
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar os Títulos Financeiros",
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}

export async function getFinancialTitleById({ commit }, financialTitleId) {
  try {
    commit("shared/setLoadingMessage", "Buscando Títulos...", {
      root: true
    });

    const financialtitle = await db
      .collection("financialTitleApproval")
      .doc(financialTitleId)
      .get()
      .then(doc => {
        return { ...doc.data(), id: doc.id };
      });
    commit("shared/setLoadingMessage", null, { root: true });

    return financialtitle;
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar o Título Financeiro",
        color: "red",
        position: "top"
      },
      { root: true }
    );
    throw new Error(error.message);
  }
}

export async function approvesRejectsJustifysFinancialTitles(
  { commit },
  payload
) {
  const { request, response, reason } = payload;
  const { id } = request;

  commit("shared/setLoadingMessage", "Enviando resposta. Aguarde...", {
    root: true
  });

  var stts = response === "pending" ? "waiting_analysis" : "response_sent";

  try {
    const financialtitleFb = await db
      .collection("financialTitleApproval")
      .doc(id)
      .get()
      .then(doc => doc.data());
    const { approvalType } = financialtitleFb;
    financialtitleFb.status = stts;
    financialtitleFb.response = response;

    const batch = db.batch();
    const setRef = db.collection("financialTitleApproval").doc(id);

    batch.update(setRef, {
      status: stts,
      reasonRejection: reason == undefined ? "" : reason,
      approvalDate: stringNow(),
      approvalHour: stringHour(),
      response
    });

    if (approvalType === "level") {
      const querySnapshot = await db
        .collection("financialTitleApproval")
        .where("branch", "==", financialtitleFb.branch)
        .where("title", "==", financialtitleFb.title)
        .where("level", "==", financialtitleFb.level)
        .where("status", "==", "waiting_analysis")
        .get();

      querySnapshot.forEach(doc => {
        if (doc.id !== id) {
          const updateRef = db.collection("financialTitleApproval").doc(doc.id);
          batch.update(updateRef, {
            status: "analyzed_by_another_user",
            reason: reason,
            approvalDate: stringNow(),
            approvalHour: stringHour(),
            response
          });
        }
      });
    }

    await batch.commit();
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });
    throw new Error("Falha no envio da resposta. Tente novamente.");
  }
}
