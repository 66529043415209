import firebaseApp from "../../../firebase";
import stringHour from "../../../helpers/stringHour";
import stringNow from "../../../helpers/stringNow";

const db = firebaseApp.firestore();

export async function getContingencies({ commit, rootState }) {
  const { protheusUserCode } = rootState.users.loggedInUser;

  try {
    commit("shared/setLoadingMessage", "Atualizando as Contingências", {
      root: true
    });

    const contingencies = [];
    const snapshot = await db
      .collection("contingencies")
      .where("user", "==", protheusUserCode)
      .where("status", "==", "waiting_analysis")
      .get();
    snapshot.forEach(doc => {
      contingencies.push({ ...doc.data(), id: doc.id });
    });

    commit("setContingencies", contingencies);
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar as Contingências",
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}

export async function getContingencieById({ commit }, contingenciesId) {
  try {
    commit("shared/setLoadingMessage", "Buscando Contingências", {
      root: true
    });

    const contingencies = await db
      .collection("contingencies")
      .doc(contingenciesId)
      .get()
      .then(doc => {
        return { ...doc.data(), id: doc.id };
      });
    commit("shared/setLoadingMessage", null, { root: true });
    return contingencies;
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar a Contingência",
        color: "red",
        position: "top"
      },
      { root: true }
    );
    throw new Error(error.message);
  }
}

export async function approvesRejectsJustifysContingencies(
  { commit },
  payload
) {
  const { request, response, reason } = payload;
  const { id } = request;

  commit("shared/setLoadingMessage", "Enviando resposta. Aguarde...", {
    root: true
  });

  var stts = response === "pending" ? "waiting_analysis" : "response_sent";

  try {
    const contingenciesFb = await db
      .collection("contingencies")
      .doc(id)
      .get()
      .then(doc => doc.data());
    const { approvalType } = contingenciesFb;
    contingenciesFb.status = stts;
    contingenciesFb.response = response;

    const batch = db.batch();
    const setRef = db.collection("contingencies").doc(id);
    //~> SE A APROVAÇÃO FOR DE 1º NIVEL PRECISO GUARDAR A JUSTIFICATIVA
    if (request.level === "01") {
      batch.update(setRef, {
        status: stts,
        reason: reason,
        approvalDate: stringNow(),
        approvalHour: stringHour(),
        response
      });
      //~> SE A APROVAÇÃO FOR DE 2º NIVEL NÃO DEVO INFORMAR NADA PARA NÃO APAGAR A JUSTIFICATIVA DO 1ºN
    } else {
      batch.update(setRef, {
        status: stts,
        approvalDate: stringNow(),
        approvalHour: stringHour(),
        response
      });
    }

    if (approvalType === "level") {
      const querySnapshot = await db
        .collection("contingencies")
        .where("branchCode", "==", contingenciesFb.branchCode)
        .where("agreementNumber", "==", contingenciesFb.contingencie)
        .where("level", "==", contingenciesFb.level)
        .where("status", "==", "waiting_analysis")
        .get();

      querySnapshot.forEach(doc => {
        if (doc.id !== id) {
          const updateRef = db.collection("contingencies").doc(doc.id);
          batch.update(updateRef, {
            status: "analyzed_by_another_user",
            reason: reason,
            approvalDate: stringNow(),
            approvalHour: stringHour(),
            response
          });
        }
      });
    }

    await batch.commit();
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });
    throw new Error("Falha no envio da resposta. Tente novamente.");
  }
}
